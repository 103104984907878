import React from "react";
import './index.css';

const arr = new Array(9).fill(1);
export default function Snow() {
  return (
    <>
    {
      arr.map((item,index) => {
        return (<div class="snowflake" key={index}>
          ❄️
        </div>)
      })
    }
    </>
  )
}